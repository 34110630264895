html,body,#root
{
    width: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
}

body {
  margin: 0;
  font-family: system-ui;
  background-color: #FCFDFF;
  color: #1A1F33;
}

/* TYPOGRAPHY */

.sans-serif-typeface {
  font-family: system-ui;
}

.typeface-no-margin {
  margin: 0;
  text-decoration: none;
  color: #332A1A;
}

.typeface-no-margin-red,
.typeface-no-margin-blue {
  margin: 0;
  color: #FAF8F5;
  text-decoration: none;
}
.typeface-no-margin-red {
  color: #FAF8F5;
}

.florence-title,
.intra-page-title {
  font-family: "EB Garamond", sans-serif; 
  font-weight: 400;
}

.florence-title {
  font-size: 2.44rem; 
  line-height: 1.17;
  margin: 0;
}

.florence-title-smaller {
  font-size: 2.2rem; 
}

.intra-page-title {
  font-size: 1.95rem;
  margin: 0;
  padding: 0;
}

.heading2 {
  font-size: 1.85rem;
}

.heading3 {
  font-size: 1.56rem;
  margin: 0;
  padding: 0;
  color: #1A1F33;
}

.heading4 {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.heading4-no-bold {
  font-size: 1.25rem;
  margin: 0;
  padding: 0;
}

.heading5 {
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.typography-big-icon {
  font-size: 5rem;
}

.body {
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
  padding: 0;
}

.center-text {
  text-align: center;
}

.small-title {
  font-size: 0.8rem;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: .05rem;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}

.small-header-button-selected,
.small-header-button,
.small-title-2 {
  font-size: 0.8rem;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.secondary-text {
  font-size: 0.8rem;
  line-height: 1.6;
  letter-spacing: .01rem;
  margin: 0;
  padding: 0;
  color: #505873;
}

.secondary-text-smaller {
  font-size: 0.7rem;
  line-height: 1.6;
  letter-spacing: .01rem;
  margin: 0;
  padding: 0;
  color: #505873;
}

.brand-color {
  color: #5978EB;
}

.primary-color {
  color: #1A1F33;
}

.secondary-color {
  color: #505873;
}

.text-wrapping {
  white-space: pre-wrap;
}

.inv-text-color {
  color: #FCFDFF;
}

.notification-flag-small,
.notification-flag-large {
  font-size: 0.7rem;
  color: #FCFDFF;
  background-color: #D42323;
  border-radius: 1rem;
  position: absolute;
  top: 0;
  right: 0;
}

.notification-flag-large {
  padding: 0.2rem;
}

.notification-flag-small {
  padding: 0.1rem 0.35rem;
}

.capitalize {
  text-transform: capitalize;
}

.column-message-profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 48vh;
}

.centered-column-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #FAF8F5;
  padding: 1.5rem 1.5rem;
  border-radius: 64px;
  min-width: 100%;
}

.color-hot {
  color: #332A1A;
}

.background-hot {
  background-color: #FAF8F5;
  min-height: 100vh;
}

.column-middle-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 80vh;
}

/* BUTTONS */

.btn-primary-header2-larger,
.btn-primary-header2,
.btn-secondary-header2,
.btn-tertiary-header2,
.btn-primary,
.btn-secondary,
.btn-tertiary {
  display: inline-block;
  cursor: pointer;
  font-size: 1rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.btn-primary-header2-larger,
.btn-primary-header2,
.btn-secondary-header2,
.btn-primary,
.btn-secondary {
  text-decoration: none;
  padding: 8px 16px;
  border-radius: 9px;
  border-bottom: 1px solid #858ca6;
  text-align: center;
}

.btn-primary {
  color: #FCFDFF;
  background-color: #516DD6;
  border: none;
}

.btn-secondary {
  color: #1A1F33;
  background-color: #FCFDFF;
  padding-top: 7px;
  padding-bottom: 7px;
}

.btn-primary-header2-larger,
.btn-primary-header2,
.btn-secondary-header2 {
  border: 1px solid #3B52AB;
  color: #3B52AB;
  background-color: #FCFDFF;
  padding: 6px 12px;
  font-weight: bold;
}

.btn-primary-header2 {
  width: 290px;
  margin-right: 32px;
  padding: 10px 0px;
  border-radius: 12px;
}

.btn-primary-header2-larger{
  width: 400px;
  margin-right: 32px;
  padding: 10px 0px;
  border-radius: 12px;
}

.link-no-decoration {
  text-decoration: none;
}

.profile-main-btn {
  width: 100%;
}

.profile-link-btn {
  color: #FCFDFF;
  background-color: #516DD6;
  border: none;
  padding: 20px 20px;
  text-decoration: none;
  border-radius: 20px;
  filter: drop-shadow(0px 2px 4px rgba(26,31,51,0.25));
}

.profile-link-btn:hover, .profile-link-btn:focus {
  background-color: #5978EB; 
}

.label-profile-link {
  letter-spacing: 0.3px;
}

.large-button-link {
  border-bottom: 2px solid #DADFF2;
  color: #1A1F33;
  background-color: #FCFDFF;
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
}

.large-button-link-no-border {
  border: none;
}

.btn-smaller, .btn-tertiary, .post-link {
  color: #516DD6;
  text-decoration: underline;
  border: none;
  background-color:#FCFDFF;
  padding: 0;
}

.btn-tertiary-header2 {
  color: #FCFDFF;
  text-decoration: underline;
  border: none;
  background-color:#3B52AB;
  padding: 0;
}

.btn-smaller {
  display: inline;
  font-size: 0.8rem;
  line-height: 1.6;
  letter-spacing: .01rem;
  cursor: pointer;
}


.post-link-active {
  font-weight: bold;
}

.btn-primary:hover, .btn-primary:focus {
  background-color: #5978EB; 
}

.svg-btn {
  position: relative;
}

.carousel-btn,
.svg-btn,
.svg-btn-secondary {
  font-size: 0.8rem;
  border: none;
  background-color: #FCFDFF;
  cursor: pointer;
  text-decoration: none;
  padding: 0;
  margin: 0;
  color: #1A1F33;
  border-radius: 20px;
}

.svg-btn-secondary {
  background-color: #F5F6FA;
}
  
.carousel-secondary {
  opacity: 0.7;
}

.carousel-tertiary {
  opacity: 0.4;
}

.large-frame-mobile {
  width: 100%;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #FCFDFF;
  border: 1px solid #858CA6;
  padding-top: 7px;
  padding-bottom: 7px;
}

.info-icon {
  height: 18px;
}


/*
.large-button-container {
  display: flex;
  justify-content: space-between;
}
*/

.large-button-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.large-button-container-mobile {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

.btn-secondary {
  color: #516DD6;
  background-color: #FCFDFF;
  border: 1px solid #516DD6;
  padding-top: 7px;
  padding-bottom: 7px;
}

.btn-tertiary {
  color: #516DD6;
  text-decoration: underline;
  border: none;
  background-color:#FCFDFF;
}

.btn-primary:hover, .btn-primary:focus {
  background-color: #5978EB; 
}

.avatar-letter:hover, .avatar-letter:focus,
.btn-secondary:hover, .btn-secondary:focus { 
  color: #28449F;
  background-color: #FFFFFF;
  border: 1px solid #28449F;
}

.confirm-button-header {
  display: flex;
  gap: 0.5rem;
}

.confirm-button {
	border-radius: 12px;
  filter: drop-shadow(0px 2px 4px rgba(26,31,51,0.25));
  max-height: 2.5rem;
  padding: 0.5rem 1rem;
}

.row-main-buttons {
  display: flex;
  gap: 1rem;
}

.row-buttons {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.row-buttons-centered {
  display: flex;
  gap: 0.3rem;
  justify-content: center;
  align-items: end;
}

.checkbox-svg {
 padding: 3px 0 0 0;
}

.column-buttons {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: start;
  justify-content: start;
}

.btn-taller {
  padding-top: 11px;
  padding-bottom: 11px;
  filter: drop-shadow(0px 2px 4px rgba(26,31,51,0.25));
}

.btn-no-padding {
  padding-left: 8px;
  padding-right: 8px;
}

.button-larger {
  padding-right: 2.1rem;
}

.btn-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;

}

.secondary-background-color {
  background-color: #F5F6FA;
}



/* SEARCH BAR */

.searchbar-header-tablet {
  display: grid;
  grid-template-columns: 1fr 10fr 1fr;
  gap: 1rem;
  align-items: center;
}

.searchbar-header {
  display: grid;
  grid-template-columns: 11fr 1fr;
  gap: .5rem;
  align-items: center;
}

.searchbar-togglemenu {
  align-self: start;
}

/* MODAL */
.modal-background {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: start;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #e5e5f7;
  background-image: radial-gradient(#444cf7 0.5px, #e5e5f7 0.5px);
  background-size: 10px 10px;
}

.modal-container {
  background-color: #E4E4E4;
  width: 90%;
  max-width: 450px;
  border-radius: 12px;
  padding: 10px 10px 20px 10px;
  margin-top: 24px;
  filter: drop-shadow(0px 2px 4px rgba(26,31,51,0.25));
}

.modal-close-button {
  display: flex;
  justify-content: flex-end;
}

.modal-teriary-button {
  background-color: #E4E4E4;
}

/* HEALTH HISTORY */

.header-habits-health-pages {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid #DADFF2;
}

.small-header-button-selected,
.small-header-button {
  line-height: 30px;
  cursor: pointer;
  color: #505873;
}

.small-header-button-selected {
  border-bottom: 2px solid #516DD6;
  color: #516DD6;
}

.header-column-buttom {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px 0 0 0;
}

.tertiary-background-white {
  background-color: rgba(245, 246, 250, 1);
}


.health-history-description-thumbnail {
  max-height: 240px;
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.health-history-description {
  background-color: #CBCBCB;
  border-radius: 8px;
  border-style: none;
  min-width: 100%;
  text-align: left;
  padding: 4px 6px;
}

.health-history-header {
  height: 60px;
  background: #FCFDFF;  /* fallback for old browsers */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 16px 0 16px;
}

.health-history-header-title {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.health-history-element-button,
.health-history-button, 
.health-history-button-smaller {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  background-color: #E4E4E4;
  border: 1px solid #CCCCCC;
  filter: drop-shadow(0px 0.5px 1px rgba(26,31,51,0.25));
  gap: 0.5rem;
}

.health-history-button {
  padding: 10px 22px;
}

.health-history-button-smaller {
  padding: 4px 8px;
}

.health-history-element-button {
  position: absolute;
  padding: 4px 8px;
  justify-content: end;
  right: 8px;
  bottom: 8px;
}

.health-history-header-buttons,
.health-history-header-buttons-smaller
{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.health-history-header-buttons-smaller {
  gap: 0.5rem;
}

.button-icon {
  height: 28px;
}

.button-icon-smaller {
  height: 14px;
}

.health-history-button-typo {
  margin: 0;
  font-family: system-ui;
  color: #505873; 
  font-size: 1.5rem;
}

.health-history-button-typo-smaller {
  margin: 0;
  font-family: system-ui;
  color: #505873; 
  font-size: 1rem;
}

.image-thumbnail-container-1,
.image-thumbnail-container-2,
.image-thumbnail-container-3 {
  position: relative;
  padding: 0;
  border-style: none;
  min-width: 100%;
  flex-shrink: 0;
  width: 274px;
  height: 220px;
  border-radius: 4px;
  overflow: hidden;
  filter: drop-shadow(0px 0.5px 1px rgba(26,31,51,0.25));
  -webkit-transform: translate3d(0,0,0);
}

.image-thumbnail-container-2 {
  display: grid;
  gap: 2px;
  grid-template-columns: 1fr 1fr;
}

.image-thumbnail-container-3 {
  display: grid;
  gap: 2px;
  grid-template: 1fr 1fr / 1fr 1fr 1fr;
}

.image-thumbnail-high {
  grid-row: 1 / span 2;
  grid-column: 1 / span 2;
}

.health-history-image-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-transform: translate3d(0,0,0);
}

.health-history-horizontal-board {
  display: flex;
  flex-direction: row;
  align-items: start;
  overflow-x: scroll;
  width: 100%;
  height: calc(100svh - 60px - 32px);
  padding: 16px 0 16px 0;
}

.health-history-new-card-button {
  box-sizing: border-box;
  background-color: #DADFF2;
  border-radius: 16px;
  padding: 8px 8px 8px 8px;
  min-width: 290px;
  max-width: 310px;
  width: 80%;
  margin: 0 0 0 16px;
	cursor: pointer;
  filter: drop-shadow(0px 2px 4px rgba(26,31,51,0.25));
}

.health-history-card-vertical-fixed-rename,
.health-history-card-vertical-fixed {
  position: relative;
  box-sizing: border-box;
  display: grid;
  background-color: #DADFF2;
  border-radius: 16px;
  padding: 8px 8px 8px 8px;
  min-width: 290px;
  max-width: 310px;
  width: 80%;
  max-height: calc(100svh - 80px - 32px);
  margin: 0 0 0 16px;
  grid-template-rows: 20px 1fr 30px;
  gap: 8px;
  filter: drop-shadow(0px 2px 4px rgba(26,31,51,0.25));
}

.health-history-card-vertical-fixed-rename {
  grid-template-rows: 20px 83px 1fr 30px;
}

.health-history-card-vertical-scroll {
  display: flex;
  flex-direction: column;
  overflow: -moz-scrollbars-vertical; 
  overflow-y: scroll;
  gap: 12px;
  -webkit-overflow-scrolling: touch;
  -webkit-transform: translate3d(0, 0, 0);
}

.health-history-card-header {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 1.5rem;
  background-color: #DADFF2;
}

.health-history-card-title {
  background-color: #DADFF2;
  height: 20px;
}

.health-history-card-footer {
  background-color: #DADFF2;
  height: 20px;
}

.health-history-card-horizontal {
  display: flex;
  flex-direction: row;
  background-color: #DADFF2;
  border-radius: 8px;
  height: 260px;
  overflow-x: scroll;
  padding: 8px 8px 22px 8px;
  gap: 12px;
}


.card-element-floating-title {
  position: absolute;
  background-color: #DADFF2;
  padding: 4px 8px;
  border-radius: 8px;
  filter: drop-shadow(0px 1px 2px rgba(26,31,51,0.25));
  z-index: 2;
  margin: 4px;
  color: #1A1F33;
  left: 3px;
  top: 3px;
  -webkit-transform: translate3d(0,0,0);
  font-size: 0.9rem;
}


.card-element {
  position: relative;
  flex-shrink: 0;
  align-self: stretch;
  background-color: rgba(245, 246, 250, 1);
  border-radius: 4px;
  padding: 4px 8px;
  filter: drop-shadow(0px 0.5px 1px rgba(26,31,51,0.25));
  min-width: 125px;
	border: none;
	cursor: pointer;
  text-align: left;
}

.header-page-icon {
  height: 30px;
}

.app-store-icon {
  height: 35px;
  fill: rgb(75, 75, 75);
}

.app-store-badge {
  display: flex; 
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  background-color: #EFEFEF;
  padding: 8px 18px;
  border-radius: 16px;
  border: solid;
  border-width: 1px 1px 4px 1px;
  border-color: #C7C7C7;
  min-width: 140px;
}

.app-badges-container {
  display: flex;
  gap: 1rem;
}

.app-badges-container-mobile {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.app-badges-container-centered {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.app-badges-container-mobile-centered {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.app-store-badge-font {
  color: #4B4B4B;
  text-decoration: none;
}

.app-store-text {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.app-badge-container {
  display: inline-flex;
}

/* SUBMISSION PAGE */
.submission-image {
  height: 100px;
  color: #1A1F33;
}

.submission-image-small {
  height: 60px;
  color: #1A1F33;
}


.submit-image-preview {
  height: 200px;
  border-radius: 16px;
}

.submit-image-preview-fixed {
  height: 180px;
  width: 140px;
  border-radius: 4px;
  object-fit: cover;
}

.submit-photo-preview {
  width: 95%;
  border-radius: 4px;
}

.photo-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.photo-thumbnail, .photo-expanded {
  display: flex;
  align-items: center;
  background-color: #F5F6FA;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 140px;
  object-fit: cover;
  margin: 1px;
  border-radius: 3px;
}

.photo-expanded {
  height: 450px;
}

.submit-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-food-exercise-submit {
  min-width: 100%;
  margin: 0 auto;
  text-align: center;
}

.submit-exercise-food-form {
  box-sizing: border-box;
  font-family: system-ui;
  font-size: 1rem;
  background-color: white;
  width: 100%;
  border: 1px solid #858CA6;
  margin: 0 auto;
  border-radius: 8px;
  height: 100px;

  padding: 8px;
}


.pair-btn-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  max-width: 100%;
}

.btn-wide {
  width: 13rem;
  max-width: 50%;
}

.btn-wider {
  width: 16rem;
  max-width: 50%;
}

/* AVATAR */ 

.avatar-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
}

/*
.avatar-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 3em;
  margin: 0 auto;
}
*/

.avatar-container-large {
  grid-template-columns: repeat(3, 1fr);
}

.avatar-container-single {
  display: flex;
  justify-content: center;
}

.avatar, .avatar-flex {
  margin: 0;
  text-decoration: none;
}

/*
.avatar-flex {
  display: inline-block;
  height: 100%;
  width: 100%;
}
*/


.avatar-letter {
  background-color: #DADFF2;
  color: #28449F;
  margin: 0;
  border-radius: 32px;
  max-width: 200px;
  min-width: 160px;
  font-size: 120px;
  text-align: center;
}

.avatar-small-letter {
  color: #1A1F33;
  background-color: #FCFDFF;
  margin: 0;
  font-size: 1.25rem;
  font-weight: bold;
  padding: 0.25rem 1.0rem;
  border-radius: 8px;
  cursor: pointer;
}

.avatar-blue {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #DADFF2;
  color: #28449F;
  height: 2.5rem;
}

.avatar-name {
  text-align: center;
}

.avatar-menu-header {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 0.5rem;
  background-color: #DADFF2;
  padding: 0.5rem;
  border-radius: 16px;

  filter: drop-shadow(0px 1px 2px rgba(26,31,51,0.25));
}

.avatar-icon-name {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.avatar-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.avatar-block {
  border-bottom: 1px solid #DADFF2;
}

.test {
  width: fit-content;
  margin: 0;
  font-size: 150px;
  font-weight: bold;
  color: transparent;
  background: url("/src/images/css-pattern-1.png");
  -webkit-background-clip: text;
  background-clip: text;
  background-size: cover;
  border: solid 1px pink;
}

/* SPACING */

.padding-xs {
  padding: 0.5rem;
}
.padding-s {
  padding: 1rem;
}
.padding-m {
  padding: 1.5rem;
}
.padding-l {
  padding: 2rem;
}
.padding-xl {
  padding: 3rem;
}
.padding-xxl {
  padding: 5rem; 
}

.horizontal-padding-xxs {
  padding-right: 0.25rem;
  padding-left: 0.25rem;
}
.horizontal-padding-xs {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.horizontal-padding-s {
  padding-right: 1rem;
  padding-left: 1rem;
}
.horizontal-padding-m {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}
.horizontal-padding-l {
  padding-right: 2rem;
  padding-left: 2rem;
}
.horizontal-padding-xxl {
  padding-right: 5rem;
  padding-left: 5rem;
}

.right-padding-xxs {
  padding-right: 0.25rem;
}
.right-padding-xs {
  padding-right: 0.5rem;
}
.right-padding-s {
  padding-right: 1rem;
}

.left-padding-s {
  padding-left: 1rem;
}
.left-padding-l {
  padding-left: 2rem;
}

.vertical-padding-xxs {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.vertical-padding-xs {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.vertical-padding-s {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.vertical-padding-m {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.vertical-padding-l {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.vertical-padding-xl {
  padding-bottom: 3rem;
  padding-top: 3rem;
}
.vertical-padding-xxl {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.top-padding-xxs {
  padding-top: 0.3rem;
}
.top-padding-xs {
  padding-top: 0.5rem;
}
.top-padding-s {
  padding-top: 1rem;
}
.top-padding-m {
  padding-top: 1.5rem;
}
.top-padding-l {
  padding-top: 2rem;
}
.bottom-padding-xxs {
  padding-bottom: 0.3rem;
}
.bottom-padding-xs {
  padding-bottom: 0.5rem;
}
.bottom-padding-s {
  padding-bottom: 1rem;
}
.bottom-padding-m {
  padding-bottom: 1.5rem;
}
.bottom-padding-l {
  padding-bottom: 2rem;
}
.bottom-padding-xl {
  padding-bottom: 3rem;
}
.bottom-padding-xxl {
  padding-bottom: 5rem;
}

.margin-xs {
  margin: 0.5rem;
}
.margin-s {
  margin: 1rem;
}
.margin-m {
  margin: 1.5rem;
}
.margin-l {
  margin: 2rem;
}
.margin-xl {
  margin: 3rem;
}
.margin-xxl {
  margin: 5rem; 
}


.vertical-margin-xxs {
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
}
.vertical-margin-xs {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.vertical-margin-s {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.vertical-margin-m {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.vertical-margin-l {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.vertical-margin-xl {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.vertical-margin-xxl {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.top-margin-xxs {
  margin-top: 0.25rem;
}
.top-margin-xs {
  margin-top: 0.5rem;
}
.top-margin-s {
  margin-top: 1rem;
}
.top-margin-m {
  margin-top: 1.5rem;
}
.top-margin-l {
  margin-top: 2rem;
}
.top-margin-xl {
  margin-top: 3rem;
}
.top-margin-xxl {
  margin-top: 5rem;
}

.bottom-margin-xxs {
  margin-bottom: 0.25rem;
}
.bottom-margin-xs {
  margin-bottom: 0.5rem;
}
.bottom-margin-s {
  margin-bottom: 1rem;
}
.bottom-margin-m {
  margin-bottom: 1.5rem;
}
.bottom-margin-l {
  margin-bottom: 2rem;
}
.bottom-margin-xl {
  margin-bottom: 3rem;
}
.bottom-margin-xxl {
  margin-bottom: 5rem;
}

.horizontal-margin-xs {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.horizontal-margin-s {
  margin-left: 1rem;
  margin-right: 1rem;
}

.right-margin-xxs {
  margin-right: 0.25rem;
}
.right-margin-xs {
  margin-right: 0.5rem;
}
.right-margin-s {
  margin-right: 1rem;
}
.right-margin-m {
  margin-right: 1.5rem;
}
.right-margin-xl {
  margin-right: 3rem;
}

.left-margin-xxs {
  margin-left: 0.25rem;
}
.left-margin-xs {
  margin-left: 0.5rem;
}
.left-margin-s {
  margin-left: 1rem;
}
.left-margin-m {
  margin-left: 1.5rem;
}
.left-margin-l {
  margin-left: 2rem;
}
/* LAYOUT */

nav {
  display: flex;
  justify-content: space-between;
  padding: 0;
  align-items: start;
}

.nav-center-align {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-left-buttons {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1rem;
}

.portal-central-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-message {
  background-color: #EBB559;
  color: #332A1A;
  padding: 4px 4px;
  font-size: 0.8rem;
  text-align: center;
  border-radius: 9px;
}

div {
  margin: 0;
}

section {
  overflow: visible;
}

.section-border-top {
  border-top: 1px solid #DADFF2;
}

.background-blue {
  width: 100%;
  background-color: #5779FB;
}

.container-full-width {
  width: 100%;
  margin: 0 auto;
  position: relative;
}


.container-simple-100vh {
  max-height: 100svh;
  background-color: #e5e5f7;
  background-image: radial-gradient(#444cf7 0.5px, #e5e5f7 0.5px);
  background-size: 10px 10px;
}

.health-background-pattern {
  background-color: #e5e5f7;
  background-image: radial-gradient(#444cf7 0.5px, #e5e5f7 0.5px);
  background-size: 10px 10px;
}

.transparent-background {
  background: rgba(0,0,0,0);
}

.container-simple-laptop, 
.container,
.image-container,
.container-simple,
.container-simple-tablet {
  position: relative;
  width: 94%;
  max-width: 500px;
  margin: 0 auto;
}

.container-simple-tablet {
  max-width: 650px;
  margin-top: 0.5rem;
}

.container-simple-laptop {
  max-width: 960px;
  margin-top: 0.5rem;
}

.container-simple-narrow {
  padding-left: 1rem;
}

.container-card {
  position: relative;
  width: 90%;
  max-width: 280px;
  margin: 0 auto;
}

.container-full-height {
  min-height: 100vh;
}

.container,
.image-container {
  min-height: 100vh;
  border-right: solid 1px #DADFF2;
}

.container-no-border {
  border-right-style: none;
}

.container-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer {
  border-top: solid 1px #CBCDD6;
}

.footer-container-laptop, 
.footer-container-tablet, 
.footer-container {
  width: 94%;
  max-width: 500px;
  margin: 0 auto;
  border-right: solid 1px #CBCDD6;
  padding: 0;
}

.footer-container-tablet {
  max-width: 650px;
}

.footer-container-laptop {
  max-width: 960px;
}

.footer-no-right-border {
  border-right-style: none;
}

.health-history-small-menu-nav,
.small-menu-nav {
  box-sizing: border-box;
  height: 50px;
  padding: 11px;
  background-color: #FCFCFC;
  border-radius: 10px;
  /* the color is defined in the SVG file */
}

.health-history-small-menu-nav {
  box-sizing: border-box;
  height: 50px;
  padding: 13px;
  background-color: #FCFCFC;
  border-radius: 10px;
}

.small-logo-nav {
  height: 60px;
  border-radius: 35px;
}

.small-logo-nav-no-border {
  height: 60px;
}

.small-logo-nav-no-border-tablet {
  height: 80px;
}

.small-logo-nav-no-border-laptop {
  height: 100px;
}

.smaller-logo-nav {
  height: 50px;
  border-radius: 35px;
}

.small-blue-logo-nav {
  height: 60px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border-radius: 35px;
}

.nav-icon-red-background,
.nav-icon-blue-background {
  background-color: #5779FB;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 25px;
}

.nav-icon-red-background {
  background-color: #F1A90C;
}

.nav-icon-blue-background {
  background-color: #516DD6;
}

.nav-icon-white-background {
  background-color: #FCFCFC;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.main-button-icon {
  height: 50px;
}

.small-icon-nav {
  height: 33px;
  border-radius: 10px;
}

.smaller-icon-nav {
  height: 30px;
  border-radius: 10px;
}

.big-icon-page {
  height: 75px;
  border-radius: 10px;
}

.icon-row {
  display: flex;
  align-items: center;
}
.icon-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon-nav-horizontal-padding {
  padding: 0 0.5rem;
}

.icon-nav-horizontal-padding-larger {
  padding: 5px 0.7rem 0 0.7rem;
}

.icon-nav-left-padding {
  padding: 0 0 0 0.5rem;
}

.medium-logo {
  height: 120px;
  border-radius: 60px;
}

.icon-paragraph {
  display: flex;
}

.loading-icon {
  animation: spin 6s infinite linear;
}

@keyframes spin {
    from {
        transform: scale(1) rotate(0deg);
    }
    to {
        transform: scale(1) rotate(360deg);
    }
}

.icon-loading-middle-page {
  height: 100vh;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.icon-loading-middle-page-short {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.icon-loading-partial {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.icon-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.column-right-aligned {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
}

.carousel {
  display: flex;
  gap: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.carousel-home {
  justify-content: space-between;
  align-items: center;
}

.chevron {
  height: 15px;
  padding: 7px;
  background-color: #FCFCFC;
  border-radius: 20px;
}


.carousel-buttons {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 8px;
}

.carousel-screenshot,
.product-screenshot {
  display: block;
  box-sizing: border-box;
  width: 80%;
  margin: 0 auto;
}

.inv-svg {
  height: 45px;
  display: block;
  background-color: #516DD6;
  border-radius: 0px;
}

.health-history-inv-svg {
  height: 45px;
  display: block;
  background-color: #E4E4E4;
  border-radius: 0px;
}

/* EVOLUTION BAR */

.evolution-columns {
  display: grid;
  grid-template-columns: repeat(18, 1fr);
  justify-items: center;
  height: 80px;
}

.evolution-strip-empty,
.evolution-strip-green-weak,
.evolution-strip-green-stark,
.evolution-strip-red {
  display: block;
  width: 8px;
  border-radius: 3px;
}

.evolution-strip-red {
  background: #f85032;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #e73827, #f85032);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #e73827, #f85032); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.evolution-strip-green-stark {
  background: #00573F;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #00573F, #0f9b0f);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #00573F, #0f9b0f); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.evolution-strip-green-weak {
  background: #56ab2f;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #a8e063, #56ab2f);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #a8e063, #56ab2f); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.evolution-strip-empty {
  background: #757F9A;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #D7DDE8, #757F9A);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #D7DDE8, #757F9A); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.spaced-around-text-row {
  display: flex;
  justify-content: space-around;
  text-align: center;
}

.spaced-between-text-row {
  display: flex;
  justify-content: space-between;
  padding: 0.1rem 0.3rem 0 0.3rem;
}

.full {
  background: #516DD6;
}

.empty {
  background: #DADFF2; 
}

/* PROGRESS BAR */
.progress-bar {
  width: 100%;
  height: 30px;
  border-radius: 10px;
  background-color: #e6e6e6;
}

.progress-bar-fill {
  height: 100%;
  border-radius: 10px;
  background-color: #2ecc71;
  transition: width 1s ease-out;
}

/* MENUS */

.row-links {
  display: flex;
  gap: 1.5rem;
}

.menu, 
.menu-notifications {
  position: absolute;
  z-index:1;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: end;
  border-radius: 15px;
  background-color: #516DD6;
  min-width: 10em;
  filter: drop-shadow(0px 2px 4px rgba(26,31,51,0.25));
}

.health-history-menu-subject,
.health-history-menu-card,
.health-history-menu {
  position: absolute;
  z-index:1;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: end;
  border-radius: 8px;
  background-color: #E4E4E4;
  border: 1px solid #CCCCCC;
  filter: drop-shadow(0px 0.5px 1px rgba(26,31,51,0.25));
  overflow: hidden;
}

.health-history-menu {
  right: 15px;
  top: 20px;
}

.health-history-menu-card {
  position: absolute;
  z-index:1;
  right: 0px;
  top: 0px;
}

.health-history-menu-subject {
  position: absolute;
  z-index:1;
  right: -20px;
  top: 60px;
}

.health-history-menu-above {
  position: absolute;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  border-radius: 8px;
  overflow: hidden;
  bottom: 44px;
  left: 8px;
}

.notification-link {
  text-decoration: none;
  color: #1A1F33;
  padding: 0.4rem 0;
  background-color: #DADFF2;
  border: none;
}

.text-right {
  text-align: right;
}

.menu-notifications {
  width: 100%;
  max-width: 500px;
}

.notification-menu-section {
  box-sizing: border-box;
  background-color: #DADFF2;
  color: #1A1F33;
  width: 100%;
  padding: 5px;
  text-align: right;
}

.menu-link {
  margin-right: 0;
  margin-bottom: 0;
  color: #FCFDFF;
  text-decoration: none;
  min-width: 17rem;
  width: 95%;
  line-height: 2.5;
  text-align: right;
}

.menu-link:hover, .menu-link:focus,
.menu-column-link:hover, .menu-column-link:focus {
  background-color: #5978EB;
}

.menu-link-selected {
  font-weight: bold;
}

.health-history-card-button,
.health-history-menu-button {
  margin-right: 0;
  margin-bottom: 0;
  color: #505873;
  background-color: #E4E4E4;
  text-decoration: none;
  min-width: 17rem;
  width: 95%;
  line-height: 2.0;
  text-align: right;
  border-style: none;
}

.health-history-card-button {
  min-width: 12rem;
}

.menu-column {
  display: flex;
  flex-direction: column;
  justify-content: start;
  min-height: 100vh;
  width: 16rem;
  background-color: #516DD6; 
  text-decoration: none;
}


.menu-column-link {
  color: #FCFDFF;
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-bottom: 0em;
  gap: 0.5rem;
  border-radius: 16px;
}

.selected-item {
  background-color: #1A1F33;
  font-weight: bold;
}
.selected-item:hover, .selected-item:focus {
  background-color: #1A1F33;
  opacity: 0.9;
}

/*

  background-color: #F5F6FA; 
*/

/* HEALTH CARD */
.health-card {
  background-color: #5779FB;
  border-radius: 8px;
  min-height: 430px;
  position: relative;
}

.card-logo {
  margin: 0 0 0 4px;
  width: 100px;
}

.card-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px 0px 10px;
}

.card-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-left: 10px;
}

.title-content-column-right-aligned {
  text-align: end;
}

.card-footer-right {
  text-decoration: underline;
  border: none;
  cursor: pointer;
  background-color: #5779FB;
  color: #FCFDFF;
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-right: 5px;
  position: absolute;
  min-width: 150px;
  bottom: 10px;
  right: 0px;
}

.card-footer-left {
  text-decoration: underline;
  border: none;
  cursor: pointer;
  background-color: #5779FB;
  color: #FCFDFF;
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-right: 5px;
  position: absolute;
  bottom: 10px;
  left: 0px;
}

.circle-left {
    width: 14px;
    height: 14px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    border-radius: 7px;
    background: #FFFFFF;
    position: absolute;
    top: 160px;
    left: -8px;
}

.circle-right {
    width: 14px;
    height: 14px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    border-radius: 7px;
    background: #FFFFFF;
    position: absolute;
    top: 160px;
    right: -8px;
}

/* EDITABLE CARD AND EDITABLE ITEMS */
.center-title {
  display: flex;
  justify-content: center;
}

.single-object-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.div-no-shadow {
  filter: none;
  background-color: #FCFDFF;
}

.last-activities-card-tablet,
.last-activities-card {
	background-color: var(--c-bg-tertiary);
	border-radius: 10px;
  filter: drop-shadow(0px 2px 4px rgba(26,31,51,0.25));
	padding: 1rem;
  width: 90%;
  max-width: 350px;
}

.last-activities-card-tablet {
  max-width: 500px;
}

.last-activity-icon {
  height: 24px;
}

.last-activity-day-selected,
.last-activity-day {
  border: none;
	background-color: var(--c-bg-tertiary);
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding: 0.8rem 0;
}

.last-activity-day-selected {
  background-color: #516DD6;
  padding: 0.8rem 0;
  border-radius: 32px;
}

.last-activity-day-name-selected,
.last-activity-day-name {
	color: var(--c-text-secondary);
	font-size: .875em;
	font-weight: 500;
	justify-self: center;
  padding: 0;
}

.last-activity-day-name-selected {
  color: #DADFF2;
}

.last-activity-day-number-selected,
.last-activity-day-number {
  padding: 0;
	border: 0;
	padding: 0;
	border-radius: 6px;
	font-weight: 600;
	border: 2px solid transparent;
	background-color: transparent;
	cursor: pointer;
	&:focus {
		outline: 0;
		color: var(--c-theme-primary);
		border: 2px solid var(--c-theme-primary-accent);	
	}	
}

.last-activity-day-number {
  color: #1A1F33;
}

.last-activity-day-number-selected {
  color: #FCFDFF;
}

.date {
  color: #1A1F33;
}

.card-background {
  background-color: #F5F6FA; 
}

.plot-card,
.editable-card {
  background-color: #F5F6FA; 
  border-radius: 16px;
  filter: drop-shadow(0px 1px 2px rgba(26,31,51,0.25));
}

.plot-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.editable-card {
  background-color: #F5F6FA; 
  border-radius: 16px;
  min-width: 90%;
  filter: drop-shadow(0px 1px 2px rgba(26,31,51,0.25));
}

.editable-card-max-width {
  min-width: 100%
}

.row-svg-icons {
  display: flex;
  flex-direction: row;
  height: 24px;
  gap: 0.5rem;
}

.editable-card-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
}

.tag-icons-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.full-row-width {
  width: 100%;
}

.editable-item-for-card-header,
.editable-item-for-card-header-center {
  display: flex;
  align-items: top;
  justify-content: space-between;
}

.editable-item-for-card-header-center {
  align-items: center;
}

.bottom-appendix-editable-item-for-card {
  background-color: #FCFDFF;
  padding: 6px 12px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  filter: drop-shadow(0px 1px 2px rgba(26,31,51,0.25));
}

.editable-item-for-card-green-weak,
.editable-item-for-card-green-stark,
.editable-item-for-card-green,
.editable-item-for-card-blue,
.editable-item-for-card-red,
.editable-item-for-card-yellow,
.editable-item-for-card {
  background-color: #FCFDFF;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  filter: drop-shadow(0px 1px 2px rgba(26,31,51,0.25));
}

.editable-item-for-card-blue,
.editable-item-for-card {
  border-left: 6px solid #516DD6;
}

.editable-item-for-card-green {
  border-left: 6px solid #5CB25D;
}

.editable-item-for-card-green-weak {
  border-left: 6px solid #53A700;
}

.editable-item-for-card-green-stark {
  border-left: 6px solid #00573F;
}

.editable-item-for-card-red {
  border-left: 6px solid #D42323;
}

.editable-item-for-card-yellow {
  border-left: 6px solid #EBB559;
}

.calendar-history {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
}


/* TAG FILTER */

.tag-selected,
.tag-filter,
.tag-orange,
.tag-orange-selected {
  color: #28449F;
  background-color: #DADFF2;
  border: none;
  text-decoration: none;
  padding: 8px 16px;
  border-radius: 25px;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: bold;
}

.tag-selected {
  background-color: #516DD6;
  color: #FCFDFF;
}

.tag-orange {
  color: #C17D1D;
  background-color: #F2E9DA;
}

.tag-orange-selected {
  color: #FAF8F5;
  background-color: #F1A90C;
}

.tag-container {
  display: inline-block;
}

.tag-small,
.tag-green-stark,
.tag-green-weak,
.tag-green,
.tag-blue, 
.tag-red {
  padding: 0 0.5rem;
  border-radius: 10px;
  text-align: center;
}

.tag-green-weak {
  background-color: #E3F2D3;
  color: #53A700;
}

.tag-green-stark {
  background-color: #CDE8E0;
  color: #00573F;
}

.tag-green {
  background-color: #DAF2DA;
  color: #458146;
}

.tag-blue {
  background-color: #DADFF2;
  color: #28449F;
}

.tag-red {
  background-color: #F2DADA;
  color: #A11E1E;
}

/* PLOTS */
.plot {
  fill: #FCFDFF;
  filter: drop-shadow(0px 2px 4px rgba(26,31,51,0.25));
}

.plot-line {
  stroke: #858CA6;
  stroke-width: 2;
}

.circle-line-red {
  fill: #D42323;
}

.plot-line-red {
  stroke: #D42323;
  stroke-width: 2;
}

.circle-line-blue {
  fill: #516DD6;
}

.plot-line-blue {
  stroke: #516DD6;
  stroke-width: 2;
}

.time-line {
  stroke: #516DD6;
  stroke-width: 2;
}

.text-plot-simple-small,
.text-plot-simple {
  fill: #505873;  
}

.text-plot-simple-small {
  font-size: 13px;
}

.text-plot,
.text-plot-small,
.text-plot-medium {
  fill: #505873;  
  text-transform: uppercase;
  font-weight: bold;
}

.text-plot-small {
  font-size: 10.5px;
}

.text-plot-medium {
  font-size: 12px;
}

.marks-bars-green {
  fill: #5CB25D;
}
.marks-bars-blue {
  fill: #516DD6;
}
.marks-bars-red {
  fill: #D42323;
}

.circle-value-green {
  fill: #DAF2DA;
}
.circle-value-blue {
  fill: #DADFF2;
}
.circle-value-red {
  fill: #F2DADA;
}
.circle-value-transparent {
  fill: #DADFF2;
  fill-opacity: 0.7;
}

/* PAGE PREVIEW */

.pagepreview-background {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
	width: 100%;
  margin: 0 auto;
	background-color: var(--c-bg-tertiary);
  border: 15px solid white;
	border-radius: 10px;
  filter: drop-shadow(0px 2px 4px rgba(26,31,51,0.25));
	padding: 1rem 0rem;
}

.exam-preview,
.react-pdf__Page,
.react-pdf__Page__canvas,
.react-pdf__Page__annotations 
.annotationLayer
{
  display: flex;
  flex-direction: column;
  justify-content: start;
  max-width: 100%;
  height: auto;
  filter: drop-shadow(0px 0.5px 1px rgba(26,31,51,0.25));
  border-radius: 5px;
  aspect-ratio: 7/10;
}

/* FILE UPLOAD */

input[type="file"]::file-selector-button {
  display: inline-block;
  cursor: pointer;
  font-size: 1rem;
  white-space: nowrap;

  text-decoration: none;
  color: #516DD6;
  background-color: #FCFDFF;
  border: 1px solid #516DD6;
  padding-top: 7px;
  padding-bottom: 7px;
  border-radius: 4px;
}

input[type=file]::file-selector-button:hover {
  filter: brightness(95%);
}

label {
  cursor: pointer;
}

#upload-photo {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.exam-custom-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

/* RANKING */

.ranking-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.2rem;
}

.ranking-position-name {
  display: flex;
  gap: 1rem;
}

.ranking-row-tall {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0.5rem;
}

.row-user {
  background-color: #DADFF2;
  border-radius: 16px;
}

.ranking-row {
  display: flex;
  justify-content: space-between;
  padding-top: 0.5rem;
}

.ranking-pos,
.ranking-pos-0,
.ranking-pos-2,
.ranking-pos-1 {
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 0.8rem;
  text-align: center;
}

.ranking-pos-0 {
  background-color: #F1B83C;
	font-weight: 600;
}

.ranking-pos-1 {
  background-color: #CCCCCC;
	font-weight: 600;
}

.ranking-pos-2 {
  background-color: #C17D1D;
	font-weight: 600;
}

/* CALENDAR */

:root {
	--c-theme-primary: #516DD6;
	--c-theme-primary-accent: #CBE8FF;
	--c-bg-primary: #D6DAE0;
	--c-bg-secondary: #EAEBEC;
	--c-bg-tertiary: #FDFDFD;
	--c-text-primary: #1F1F25;
	--c-text-secondary: #999FA6;
}

button {
	font: inherit;
	cursor: pointer;
	&:focus {
		outline: 0;
	}
}

.datepicker {
	width: 95%;
	max-width: 350px;
  min-width: 300px;
	background-color: var(--c-bg-tertiary);
	border-radius: 10px;
  filter: drop-shadow(0px 2px 4px rgba(26,31,51,0.25));
	padding: 1rem;
}

.datepicker-low-padding {
  padding: 1rem;
}

.datepicker-top {
	margin-bottom: 1rem;
}

.btn-group {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 1rem;
	margin-top: -.5rem;
}
.month-selector {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.arrow {
	display: flex;
	align-items: center;
	justify-content: center;
	border: 0;
	background-color:#FFF;
	border-radius: 12px;
	width: 2.5rem;
	height: 2.5rem;

  filter: drop-shadow(0px 2px 4px rgba(26,31,51,0.25));
}
.month-name {
	font-weight: 600;
}
.datepicker-calendar {
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	grid-row-gap: 1rem;
  max-width: 450px;
  margin: 0 auto;
}
.day, .date {
	justify-self: center;
}
.day {
	color: var(--c-text-secondary);
	font-size: .875em;
	font-weight: 500;
	justify-self: center;
}
.date {
	border: 0;
	padding: 0;
	width: 2.25rem;
	height: 2.25rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
	font-weight: 600;
	border: 2px solid transparent;
	background-color: transparent;
	cursor: pointer;
	&:focus {
		outline: 0;
		color: var(--c-theme-primary);
		border: 2px solid var(--c-theme-primary-accent);	
	}	
}
.date {
  color: #1A1F33;
}
.day-pendent {
  color: #A11E1E;
	background-color: #F2DADA;
  border-radius: 20px;
}
.day-confirmed {
  color: #458146;
	background-color: #DAF2DA;
  border-radius: 20px;
}
.day-streak {
  color: #FAF8F5;
	background-color: #F1A90C;
  border-radius: 20px;
}
.day-not-streak {
  color: #516DD6;
	background-color: #DADFF2;
  border-radius: 20px;
}
.day-average {
  color: #28449F;
	background-color: #DADFF2;
  border-radius: 20px;
}

.day-selected {
  background-color:#CBE8FF;
}

.faded {
	color: var(--c-text-secondary);
}
.current-day {
	color: #FFF;
	border-color: var(--c-theme-primary);
	background-color: var(--c-theme-primary);
	&:focus {
		background-color: var(--c-theme-primary-accent);
	}
}

/* FLORENCE MESSAGES */

.message-background-container {
  width: 100%;
  max-width: 450px;
  background-size: cover;
}

.florence-warning, .florence-message, .forum-message {
  border: solid 1px gray;
  border-radius: 16px;
}

.message-background {
  background-color: #F2DAF0; 
}

.florence-message {
  border-color: #F2DAF0;
  background-color: #F2DAF0; 
  filter: drop-shadow(0px 1px 2px rgba(26,31,51,0.25));
  max-width: 450px;
}

.florence-warning {
  border-color: #EBB559;
  background-color: #F2E9DA; 
}

.forum-message {
  width: 100%;
  border-color: #516DD6;
  background-color: #F5F6FA;
  padding: 0.9rem 0.5rem;
}

.forum-message-background {
  background-color: #F5F6FA;
}

.blue-message-background {
  background-color: #F5F6FA;
}


/* QUESTION ANSWERING*/
.progress-bar {
  display: flex;
  gap: 6px;
}

.rectangle {
  display: block;
  width: 100%;
  height: 6px;
  border-radius: 3px;
}

.full {
  background: #516DD6;
}

.empty {
  background: #DADFF2; 
}

.colorful-background-tablet,
.colorful-background {
  box-sizing: border-box;
  width: 100%;
  background: rgb(0,44,206);
  background: linear-gradient(121deg, rgba(0,44,206,0.4) 0%, rgba(238,0,214,0.4) 100%);
  padding-left: 3%;
  padding-right: 3%;
  margin-left: 0;
  margin-right: 0;
}

.use-app-message-background-tablet,
.use-app-message-background {
  box-sizing: border-box;
  width: 100%;
  padding-left: 3%;
  padding-right: 3%;
  margin-left: 0;
  margin-right: 0;
  background-color: #DADFF2;
}

.use-app-message-background-tablet,
.colorful-background-tablet {
  box-sizing: border-box;
  max-width: 700px;
  margin: 0 auto;
  border-radius: 14px;
}


.profile-question-form-rounded,
.profile-question-form {
  display: block;
  box-sizing: border-box;
  border: 1px solid #858CA6; 
  width: 96%;
  height: 200px;
  margin: 0 auto;
  border-radius: 4px;
  padding: 8px;
  font-family: system-ui;
  font-size: 1rem;
}

.profile-question-form-rounded {
  border-radius: 28px;
  text-align: center;
}

.form-full-width {
  width: 100%
}

.short-form {
  height: 100px;
}

.shorter-form {
  height: 50px;
}

::placeholder {
  font-size: 1rem;
  color: #505873;
}

/* LOGIN FORM */
.login-container {
  background-color: #FCFCFC;
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
  border: none; 
  border-radius: 8px;
  filter: drop-shadow(0px 1px 2px rgba(26,31,51,0.25));
}

.input-text {
  box-sizing: border-box;
  min-width: 100%;
  height: 2.5rem;
  text-indent: 0.5rem;
  border-radius: 8px;
  border: solid 1px #858CA6;
  font-family: system-ui;
  font-size: 1rem;
}

.input-text-two-columns {
  box-sizing: border-box;
  min-width: 0;
  max-width: 100%;
  height: 2.5rem;
  text-indent: 0.5rem;
  border-radius: 4px;
  border: solid 1px #858CA6;
  font-family: system-ui;
  font-size: 1rem;
}


.closed-radio-button {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  min-height: 2.5rem;
  border: solid 1px #858CA6;
  border-radius: 4px;
  padding: 0 0.5em;
  margin: auto;
  gap: 0.25em;
}

.birthday-input-text,
.birthday-input {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: solid 1px #858CA6;
  font-family: system-ui;
  font-size: 1rem;
  height: 2.5rem;
}

.birthday-input-text {
  text-indent: 0.5rem;
  height: 2.5rem;
}

.row-select-birthdate {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  column-gap: 0.5em;
  max-width: 100%;
}

.row-radio-buttons-single {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 0.5em;
  row-gap: 0.5em;
}

.row-radio-buttons-narrow {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 0.5em;
  row-gap: 0.5em;
}

.row-radio-buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1em;
  row-gap: 0.5em;
}

.row-many-radio-buttons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1em;
  row-gap: 0.5em;
}

.radio-large {
  grid-column: span 2;
}

/* FORUM */

.searchbar {
  box-sizing: border-box;
  display: flex;
  gap: 0;
  height: 3rem;
}

.searchinput,
.form-post-title,
.comment-form,
.post-form {
  box-sizing: border-box;
  font-family: system-ui;
  font-size: 1rem;
  background-color: white;
  width: 100%;
  color: #1A1F33;
}

.searchinput {
  display: inline-block;
  font-family: system-ui;
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
  border: 1px solid #858CA6;
  text-indent: 1rem;
}

.form-post-title {
  height: 2rem;
  border-radius: 8px;
  border: 1px solid #858CA6;
  padding: 8px;
}

.comment-form {
  display: block;
  border: 1px solid #858CA6; 
  height: 100px;
  margin: 0 auto;
  border-radius: 4px;

  padding: 8px;
}
.post-form {
  display: block;
  border: 1px solid #858CA6;
  margin: 0 auto;
  border-radius: 8px;
  height: 50vh;

  padding: 8px;
}

.post-image-desc {
  height: 25vh;
}

.magnifyingglass {
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;

  border-top: 1px solid #858CA6;
  border-bottom: 1px solid #858CA6;
  border-right: 1px solid #858CA6;
}

.tags-vertical-menu-stretch,
.tags-vertical-menu {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  justify-content: start;
  align-items: start;
}

.tags-vertical-menu-stretch {
  align-items: stretch;
}

.tags-horizontal-menu-tablet,
.forum-main-horizontal-menu-tablet {
  display: flex;
  gap: 1.3rem;
  align-items: center;
}

.tags-horizontal-menu,
.forum-main-horizontal-menu {
  display: flex;
  gap: 0.7rem;
  align-items: center;
}

.horizontal-small-gap {
  gap: 0.5rem;
}

.forum-main, .post-thumbnail, .bottom-border {
  border-bottom: 2px solid #DADFF2;
}

.post-useful-info, .post-stats {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.post-stats {
  gap: 0.8rem;
  flex-wrap: wrap;
}

.post-image {
  max-width: 100%;
  max-height: 700px;
  object-fit: cover;
}

.tags-row {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.thumbnail-link, .thumbnail-link:visited {
  text-decoration: none;
  color: inherit;
}

.return-link {
  padding: 0;
  border: none;
  background-color:#FCFDFF;
  display: flex;
  gap: 0.5rem;
  text-decoration: none;
  color: #1A1F33;
}

.button-content {
  text-align: left;
}

.health-history-description,
.health-history-description-thumbnail,
.button-content,
.content,
.comment {
  white-space: pre-wrap;
  color: #1A1F33;
}

/* MEDIA QUERIES */
.img-larger {
  width: 100%;
}

.lateral-menu {
  display: grid;
  grid-template-columns: 20% 80%;
}

.container-wider {
  width: 80%;
  max-width: 800px;
}

.container-two-columns {
  width: 100%;
  max-width: 1000px;
}

.content-two-columns-phone {
  display: grid;
  grid-template-columns: 50px 1fr;
  column-gap: 0.5em;
}

.content-two-columns-exam {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 0.5em;
  max-width: 100%;
}

.content-two-columns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 0.5em;
  row-gap: 0.5em;
}

.content-three-columns {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 0.5em;
}

.content-four-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 0.5em;
  row-gap: 0.5em;
}

.comment,
.comment-background {
  border-radius: 8px;
  background-color: #F5F6FA;
}

.comment {
  padding: 0 0.5rem 0.5rem 0.5rem;
}

.tag-selection {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.tags-horizontal-menu-tablet,
.tags-horizontal-menu {
  flex-wrap: wrap;
}

/* EATING */

.food-image-title-row {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.food-image {
  width: 85px;
  height: 85px;
  object-fit: cover;
  border-radius: 8px; 
}

/* Header 2 */

.header2-background-laptop,
.header2-background {
  width: 100%;
  height: 60px;
  background-color: #3B52AB;
  display: flex;
  justify-content: center;
}

.header2-background-laptop {
  height: 100px;
}

.header2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header2-buttons {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  gap: 1rem;
}

/* home hero */

.home-hero-laptop,
.home-hero-tablet,
.home-hero-phone {
  position: relative;
  height: 400px;
  width: 100%;
  object-fit: cover;
}

.home-hero-tablet {
  height: 500px;
}

.home-hero-laptop {
  height: 600px;
}

.home-hero-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.home-hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: rgb(59,82,171);
  background: linear-gradient(180deg, rgba(59,82,171,1) 0%, rgba(0,0,0,0.2) 100%);
}

.home-hero-text-laptop,
.home-hero-text {
  color: #FDFDFD;
  text-align: center;
  max-width: 300px;
  padding: 48px 32px; 
}

.home-hero-text-laptop{
  max-width: 420px;
}

.hero-content-phone {
  width: 100%;
  display: flex;
  justify-content: center;
}

.hero-content-laptop,
.hero-content-tablet {
  margin: 0 auto;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: end;
}

.hero-content-laptop {
  max-width: 960px;
}

.home-centered-text {
  text-align: center;
}

.product-horizontal-view {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
}

.product-horizontal-view-laptop {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 98px;
}

.product-content {
  display: flex;
  flex-direction: column;
  margin: auto 0;
  order: 1;
}

.grid-last {
  order: -1;
}

/* OLD - NOT REVISED */

.dashboard-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 3px 8px rgba(0,0,0,.1);
  padding-left: 30px;
  padding-right: 30px;
}

.image-title-header {
  display: flex;
  align-items: center;
}

.florence-logo {
  height: 70px;
}

.florence-text {
  height: 50px;
}

.header-left-portion {
  display: flex;
  align-items: center;
}

.header-button {
  font-weight: 300;
  font-size: 14px;
  margin-right: 20px;
  cursor: pointer;
  text-decoration: none;
  color: white;
  background-color: #5978eb;
  padding: 10px 15px 10px 15px;
  border-radius: 30px;
}

.header-button:hover {
  color: white;
  background-color: #1d44d0;
}

.header-button-reverse {
  font-weight: 300;
  font-size: 14px;
  margin-right: 20px;
  cursor: pointer;
  text-decoration: none;
  color: #5978eb;
  background-color: white;
  padding: 10px 15px 10px 15px;
  border-radius: 30px;
  border: #5978eb;
  border-style: solid;
  border-width: 1px;
}

.header-button-reverse:hover {
  background-color: #5978eb;
  color: white;
}

.about-button {
  margin-left: 30px;
  color: black;
  background-color:gainsboro;
}

.about-button:hover {
  background-color: darkgrey;
}




.home-hero {
  position: relative;
  text-align: center;
}

.home-image-hero {
  object-fit: cover;
  width: 100vw;
  height: 680px;
  margin-bottom: 10px;
  filter: brightness(50%);
}

.info-hero {
  color: white;
  position: absolute;
  height: 0px;
  z-index: 1;
  width: 100%; 
  left: 0px;
  top: 480px;   
}

.home-title {
  font-size: 36px;
  font-weight: 300;
}

.home-subtitle {
  color: #e3dcd5;
  font-size: 22px;
  font-weight: 300;
  text-wrap: wrap;
  margin-left: 20%;
  margin-right: 20%;
}

.home-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  row-gap: 10px;
  margin-bottom: 10px;
}

.home-grid-block {
  position: relative;
  text-align: center;
  /* cursor: pointer; */
}

.home-grid-image {
  object-fit: cover;
  width: 48vw;
  height: 680px;
}

.info-in-image {
  position: absolute;
  z-index: 1;
  width: 100%; 
  left: 0px;
  top: 0px; 
}

.text-in-image {
  color: white;
  font-size: 32px;
  font-weight: 300;
  margin-bottom: 20px;
}

.text-black {
  color:rgb(69, 69, 69);
}

.multiple-buttons-in-image {
  display: flex;
  flex-direction: row;
  justify-content:center;
  gap: 20px;
}

.button-in-image {
  font-weight: 300;
  font-size: 18px;
  cursor: pointer;
  text-decoration: none;
  color: white;
  background-color:#5978eb;
  padding: 10px 15px 10px 15px;
  border-radius: 30px;
  max-width: 150px;
}

.button-in-image:hover {
  color: white;
  background-color: #1d44d0;
}

.button-in-image-reverse {
  font-weight: 300;
  font-size: 18px;
  cursor: pointer;
  text-decoration: none;
  color: #5978eb;
  background-color:white;
  padding: 10px 15px 10px 15px;
  border-radius: 30px;
  max-width: 150px;
  border-style: none; 
}

.button-in-image-reverse:hover {
  background-color: #5978eb;
  color: white;
}

/* .home-grid-image:hover {
  filter: brightness(80%);
} */

.container-headless {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.florence-logo-headless {
  height: 120px;
} 

.product-choice {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 30px;
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 20px;
}

.text-product-button {
  border-color: #5978eb;
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-radius: 20px;
  padding: 25px 15px 25px 15px;
  cursor: pointer;
  text-align:left;
  display: inline-flex; /* keep the inline nature of buttons */
  flex-direction: column;
  align-items: flex-start; /* this is default */
}

.text-product-button-reverse {
  background-color: #5978eb;
  color:white;  
}

.text-product-button > p {
  font-size: 16px;
}

.product-options-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-choice-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-size: 16px;
  margin-top: 30px;
  min-width: 140px;
  height: 40px;
  border-radius: 20px;
  background-color: #5978eb;
  color:white;  
  border: none;
  text-decoration: none;
}

.button-disabled {
  border: none;
  pointer-events: none;
  text-decoration: none;
}

.button-abled:hover {
  background-color: #1d44d0;  
}


.button-login-div {
  text-align: center;
}

.button-login {
  margin-top: 25px;
  min-width: 200px;
  max-width: 300px;
  height: 40px;
  border-radius: 20px;
  background-color: #5978eb;
  color:white;  
  border: none;
  text-decoration: none;
  cursor: pointer;
}

.button-login:hover {
  background-color: #1d44d0;  
}

.button-login-reverse {
  margin-top: 25px;
  min-width: 200px;
  max-width: 300px;
  height: 40px;
  border-radius: 20px;
  color:#5978eb;
  border-color: #5978eb;
  background-color: white;
  border-style: solid;
  border-width: 1px;
  text-decoration: none;
  cursor: pointer;
}

.button-login-reverse:hover {
  background-color: #5978eb;
  color:white
}

.error {
  color: red;
}

.pageTitle {
  font-weight: 300;
  padding-left: 20px;
}

.dashboard-blocks {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.simple-table {
  font-weight: 200;
  box-shadow: 0 3px 8px rgba(0,0,0,.1);
  display: inline-block;
  margin: 20px;
  padding: 20px;
  padding-bottom: 20px;
  line-height: 25px;
  max-width: 300px;
  min-width: 150px;
  max-height: 200px;
  overflow-y: scroll;
}


td {
  /* border: 1px solid black; */
  color: whitesmoke;
}

th {
  /* border: 1px solid black; */
  color:gold;
  height: 20px;
}

.two-column-table {
  font-weight: 200;
  font-size: 16px;
  margin: 20px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 5px 12px rgba(0,0,0,.2);
  background-color: #5978eb;
  min-width: 200px;
  text-align: left;
  display: block;
  max-height: 200px;
  overflow-y: auto;
  max-width: 500px;
}

th, td {
  padding: 2px 10px;
  vertical-align: top;  
}
